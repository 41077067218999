import { useEffect } from 'react'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Layout from 'components/layout/Layout'
import Page404 from 'features/404/Page404'
import { DEFAULT_NAMESPACES } from 'constants/i18n'
import { useRouter } from 'next/router'
import { Criticality, Flow, Severity } from 'types/logging'
import { log } from 'utils/logging'
import { ROUTES } from 'constants/routes'

const Page404Container = () => {
  // Router
  const { asPath, locale } = useRouter()

  // Log producer not found error
  useEffect(() => {
    const splitRegistrationRoute = ROUTES.REGISTRATION[locale].split('/')
    const isRegistrationPage =
      asPath.startsWith(`/${splitRegistrationRoute[0]}`) && asPath.endsWith(splitRegistrationRoute[splitRegistrationRoute.length - 1])

    if (isRegistrationPage) {
      log({
        error: `Failed to fetch registration page for producer with slug: ${asPath}`,
        criticality: Criticality.CRITICAL,
        severity: Severity.ERROR,
        identifier: `[${Flow.REGISTRATION}:getStaticProps:producerNotFound]`
      })
    }
  }, [asPath, locale])

  return (
    <Layout>
      <Page404 />
    </Layout>
  )
}

export const getStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, [...DEFAULT_NAMESPACES, 'error404']))
    }
  }
}

export default Page404Container

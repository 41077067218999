import { useTranslation } from 'next-i18next'
import { routes } from 'utils/routes'
import { useRouter } from 'next/router'
import parse from 'html-react-parser'
import styles from './Page404.module.scss'
import { Heading } from '@boltenergy-be/design-system'

const Page404 = () => {
  // i18n
  const { t } = useTranslation('error404')
  const { locale: language } = useRouter()

  return (
    <div className={styles['page-404']}>
      <div className="container">
        <Heading as="h2" variant="h3">
          {t('thisPageIsNotAvailable', 'Het spijt ons! Deze pagina is niet beschikbaar.')}
        </Heading>

        <p className="mb-none">{parse(t('backToHome', { link: routes(language).home }))}</p>
      </div>
    </div>
  )
}

export default Page404
